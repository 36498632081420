export const EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_REQUEST =
	"EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_REQUEST"
export const EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_RECEIVE =
	"EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_RECEIVE"
export const EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_FAILURE =
	"EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_FAILURE"

export const EXTRACTS_CONSULTANT_EMAILS_UPLOAD_REQUEST =
	"EXTRACTS_CONSULTANT_EMAILS_UPLOAD_REQUEST"
export const EXTRACTS_CONSULTANT_EMAILS_UPLOAD_RECEIVE =
	"EXTRACTS_CONSULTANT_EMAILS_UPLOAD_RECEIVE"
export const EXTRACTS_CONSULTANT_EMAILS_UPLOAD_FAILURE =
	"EXTRACTS_CONSULTANT_EMAILS_UPLOAD_FAILURE"
