import { toast } from "react-toastify"
import * as ActionTypes from "./types"

export interface ConsultantEmailsState {
	uploadInProgress: boolean
	downloadInProgress: boolean
	uploadErrors: string
}

const initialState: ConsultantEmailsState = {
	uploadInProgress: false,
	downloadInProgress: false,
	uploadErrors: ""
}

function consultantEmailsReducer(
	state: ConsultantEmailsState = initialState,
	action: any
): ConsultantEmailsState {
	switch (action.type) {
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_RECEIVE:
			toast.success("Extract started. Check slack for details.")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_EXTRACT_TO_SFTP_FAILURE:
			toast.error("Something went wrong.")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_UPLOAD_REQUEST:
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_UPLOAD_RECEIVE:
			toast.success("Successfully uploaded file and updated consultants")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		case ActionTypes.EXTRACTS_CONSULTANT_EMAILS_UPLOAD_FAILURE:
			toast.error(
				"Something went wrong with the upload - some consultants may have still been updated if there is no specific error relating to them"
			)
			const message =
				(action.payload &&
					action.payload.response &&
					action.payload.response.Message) ||
				"The upload failed but the response is empty. This could be because the server was not contacted at all. Check the Chrome Developer Tools console for the error message."
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: message
			}
	}
	return state
}

export default consultantEmailsReducer
