import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export type BridgeRecord = {
	bridgeActivityId: number
	activityType: string
	activityName: string
	createDate: string
}

export type ImportUnpackTransformMerge = {
	uploadInProgress: boolean
	uploadErrors: string
}

export interface IntegrationsState {
	bridge: {
		records: BridgeRecord[]
		updated: boolean
	}
	unpackTransformMerge: ImportUnpackTransformMerge
	fixPatientInProgress: boolean
}

const initialState: IntegrationsState = {
	bridge: {
		records: [],
		updated: false
	},
	unpackTransformMerge: {
		uploadInProgress: false,
		uploadErrors: ""
	},
	fixPatientInProgress: false
}

function clientReducer(
	state: IntegrationsState = initialState,
	action: any
): IntegrationsState {
	switch (action.type) {
		case ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE:
			return {
				...state,
				bridge: {
					records: action.payload,
					updated: true
				}
			}
		case ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE:
			toast.error("Unable to get bridge records")
			return state
		case ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE:
			toast.error("Unable to delete all bridge records")
			break
		case ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE:
			toast.success("All bridge records deleted successfully")
			return {
				...state,
				bridge: {
					...state.bridge,
					updated: false
				}
			}
		case ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_FAILURE:
			toast.error("Unable to delete bridge record")
			break
		case ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_RECEIVE:
			toast.success("Bridge record deleted successfully")
			return {
				...state,
				bridge: {
					...state.bridge,
					updated: false
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_REQUEST:
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: true,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_RECEIVE:
			toast.success("Successfully sent patients; check Slack for details")
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: false,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_FAILURE:
			toast.error(
				"Unable to upload and process the patients. Check below and Slack."
			)
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: false,
					uploadErrors: action.payload.response.Message
				}
			}
	}
	return state
}

export default clientReducer
