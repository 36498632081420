import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

export interface LillyBreastCancerAssessmentExtractState
	extends Omit<ExtractState, "downloadInProgress"> {
	sendToSftpInProgress: boolean
}

const initialLillyBreastCancerAssessmentExtract: LillyBreastCancerAssessmentExtractState =
	{
		sendToSftpInProgress: false
	}

function lillyBreastCancerAssessmentExtractReducer(
	state: LillyBreastCancerAssessmentExtractState = initialLillyBreastCancerAssessmentExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.LILLY_BREASTCANCER_ASSESSMENT_EXTRACT_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.LILLY_BREASTCANCER_ASSESSMENT_EXTRACT_RECEIVE:
			toast.success("Started extract to SFTP. Check Slack for progress")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.LILLY_BREASTCANCER_ASSESSMENT_EXTRACT_FAILURE:
			toast.error("Unable to start extract. Contact tech team")
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default lillyBreastCancerAssessmentExtractReducer
