import { zoneRegistry } from "./ZoneRegistry"

import "./zone-indicator.scss"
import { useLayoutEffect } from "react"

export const useZoneIndicator = (display: boolean) => {
	const zone = zoneRegistry.get()
	const cssClass = zone && zone.settings.cssClass

	useLayoutEffect(() => {
		if (!display) {
			return
		}

		if (!zone) {
			return
		}

		if (!zone.settings.showWarning) {
			return
		}

		document.body.classList.add(cssClass)
		return () => document.body.classList.remove(cssClass)
	}, [display])
}
