import { combineReducers } from "redux"
import lillyBreastCancerAssessmentExtractReducer, {
	LillyBreastCancerAssessmentExtractState
} from "./lillybreastcancerassessmentextract/reducer"

export interface LillyBreastCancerExtractState {
	lillyBreastCancerAssessmentExtract: LillyBreastCancerAssessmentExtractState
}

const lillyBreastCancerReducer = combineReducers({
	lillyBreastCancerAssessmentExtract:
		lillyBreastCancerAssessmentExtractReducer
})

export default lillyBreastCancerReducer
