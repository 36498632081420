import { combineReducers } from "redux"
import lillyIbdAssessmentExtractReducer, {
	LillyIbdAssessmentExtractState
} from "./lillyibdassessmentextract/reducer"

export interface LillyIbdExtractState {
	lillyIbdAssessmentExtract: LillyIbdAssessmentExtractState
}

const lillyIbdReducer = combineReducers({
	lillyIbdAssessmentExtract: lillyIbdAssessmentExtractReducer
})

export default lillyIbdReducer
